import Icon from '@headout/aer/src/atoms/Icon';

import glasses from 'Static/svg/glasses.svg';

export const Diamond = () => (
	<svg
		version='1.1'
		id='Layer_1'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		viewBox='0 0 128 128'
		// @ts-expect-error TS(2322): Type '{ enableBackground: string; }' is not assign... Remove this comment to see the full error message
		style={{ enableBackground: 'new 0 0 128 128' }}
	>
		<g id='图层_1'>
			<g>
				<polygon
					style={{ fill: '#E1F5FE' }}
					points='4.01,47.94 21.49,21.43 35.03,36.9 		'
				/>
				<polygon
					style={{ fill: '#81D4FA' }}
					points='44.11,68.26 4.01,47.94 35.03,36.9 		'
				/>
				<polygon
					style={{ fill: '#64B5F6' }}
					points='63.94,43.06 35.03,36.9 44.11,68.26 		'
				/>
				<polygon
					style={{ fill: '#0288D1' }}
					points='123.87,47.94 106.39,21.43 92.85,36.9 		'
				/>
				<polygon
					style={{ fill: '#81D4FA' }}
					points='83.77,68.26 123.87,47.94 92.85,36.9 		'
				/>
				<polygon
					style={{ fill: '#E1F5FE' }}
					points='63.94,43.06 92.85,36.9 83.77,68.26 		'
				/>
				<polygon
					style={{ fill: '#B2EBF2' }}
					points='83.77,68.26 63.94,43.06 44.11,68.26 		'
				/>
				<polygon
					style={{ fill: '#B3E5FC' }}
					points='43,10.06 84.88,10.06 106.39,21.43 92.85,36.9 63.94,43.06 35.03,36.9 21.49,21.43 		'
				/>
				<polygon
					style={{ fill: '#1E88E5' }}
					points='63.94,117.27 4.01,47.94 44.11,68.26 		'
				/>
				<polygon
					style={{ fill: '#B3E5FC' }}
					points='63.94,117.27 123.87,47.94 83.77,68.26 		'
				/>
				<polygon
					style={{ fill: '#E1F5FE' }}
					points='83.77,68.26 83.77,68.26 63.94,117.27 44.11,68.26 		'
				/>
			</g>
		</g>
	</svg>
);
export const Heart = () => (
	<svg
		version='1.1'
		id='Layer_3'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		viewBox='0 0 128 128'
		// @ts-expect-error TS(2322): Type '{ enableBackground: string; }' is not assign... Remove this comment to see the full error message
		style={{ enableBackground: 'new 0 0 128 128' }}
	>
		<g>
			<path
				style={{ fill: '#EF5592' }}
				d='M93.96,8.96C72.05,8.96,64,31.34,64,31.34S56.06,8.96,33.99,8.96c-16.58,0-35.48,13.14-28.5,43.01
		c6.98,29.87,58.56,67.08,58.56,67.08s51.39-37.21,58.38-67.08C129.41,22.1,111.87,8.96,93.96,8.96z'
			/>
			<g>
				<path
					style={{ fill: '#DA2E75' }}
					d='M30.61,11.19c17.2,0,25.74,18.49,28.5,25.98c0.39,1.07,1.88,1.1,2.33,0.06l2.52-5.88
			C60.41,20,50.65,8.96,33.99,8.96c-6.9,0-14.19,2.28-19.86,7.09C19.14,12.76,25.01,11.19,30.61,11.19z'
				/>
				<path
					style={{ fill: '#DA2E75' }}
					d='M93.96,8.96c-5.29,0-9.77,1.54-13.53,3.85c2.64-1.02,5.56-1.62,8.8-1.62
			c16.21,0,30.72,12.29,24.17,40.7c-5.62,24.39-38.46,53.98-48.49,65.27c-0.64,0.72-0.86,1.88-0.86,1.88s51.39-37.21,58.38-67.08
			C129.41,22.1,110.53,8.96,93.96,8.96z'
				/>
			</g>
			<g>
				<g>
					<g>
						<path
							style={{ fill: '#F386AB' }}
							d='M17,24.82c3.75-4.68,10.45-8.55,16.13-4.09c3.07,2.41,1.73,7.35-1.02,9.43
					c-4,3.04-7.48,4.87-9.92,9.63c-1.46,2.86-2.34,5.99-2.79,9.18c-0.18,1.26-1.83,1.57-2.45,0.46C12.73,41.94,11.53,31.64,17,24.82
					z'
						/>
					</g>
				</g>
			</g>
		</g>
		<circle style={{ fill: '#FDD835' }} cx='74.03' cy='88.49' r='3.6' />
		<circle style={{ fill: '#FFF891' }} cx='77.63' cy='44.27' r='4.28' />
		<rect
			x='85.12'
			y='69.35'
			transform='matrix(0.7071 -0.7071 0.7071 0.7071 -26.1349 85.4087)'
			style={{ fill: '#FFF891' }}
			width='9.81'
			height='9.81'
		/>
		<g>
			<path
				style={{ fill: '#FDD835' }}
				d='M65.43,74.89l-7.81-2.52c-5-1.65-7.92-5.69-9.29-10.77L44.9,44.87c-0.09-0.33-0.41-0.56-0.98-0.56
		c-0.57,0-0.89,0.23-0.98,0.56l-3.43,16.74c-1.38,5.08-4.3,9.12-9.29,10.77l-7.81,2.52c-1.11,0.36-1.12,1.92-0.02,2.29l7.86,2.71
		c4.98,1.65,7.88,5.69,9.26,10.75l3.44,16.55c0.09,0.33,0.4,0.55,0.98,0.55s0.89-0.23,0.98-0.55l3.44-16.55
		c1.38-5.06,4.28-9.09,9.26-10.75l7.86-2.71C66.56,76.81,66.54,75.25,65.43,74.89z'
			/>
			<path
				style={{ fill: '#FFF176' }}
				d='M66.18,75.59c-0.12-0.31-0.36-0.58-0.74-0.7l-7.81-2.52c-5-1.65-7.92-5.69-9.29-10.77L44.9,44.87
		c-0.05-0.19-0.19-0.34-0.39-0.44l1.07,16.6c0.82,7.67,1.53,11.28,7.65,12.33C58.48,74.27,64.56,75.31,66.18,75.59z'
			/>
			<path
				style={{ fill: '#F4B400' }}
				d='M66.19,76.46l-13.54,3.11c-4.77,1.21-7.4,3.98-7.4,11.2l-1.14,16.95c0.46-0.05,0.71-0.25,0.79-0.53
		l3.44-16.55c1.38-5.06,4.28-9.09,9.26-10.75l7.86-2.71C65.84,77.06,66.07,76.78,66.19,76.46z'
			/>
		</g>
		<g>
			<path
				style={{ fill: '#FDD835' }}
				d='M109.55,25.17c-6.09-2.01-6.64-4.28-7.67-8.09L99.33,5.07c-0.15-0.58-1.66-0.58-1.82,0l-1.73,11.33
		c-1.03,3.8-3.22,6.83-6.96,8.07l-6.69,2.59c-0.83,0.27-0.84,1.44-0.02,1.72l6.73,2.12c3.73,1.24,5.91,4.26,6.94,8.05l1.73,10.96
		c0.16,0.57,1.66,0.57,1.81,0l2.03-10.93c1.03-3.82,2.6-6.85,7.67-8.09l6.37-2.13c0.83-0.28,0.82-1.45-0.01-1.72L109.55,25.17z'
			/>
			<path
				style={{ fill: '#FFF176' }}
				d='M99.82,16.95c0.62,5.74,0.94,7.34,5.59,8.52l10.43,1.89c-0.1-0.14-0.25-0.25-0.45-0.32l-5.84-1.88
		c-5.34-1.83-6.7-3.92-7.81-8.79l-2.4-11.3c-0.05-0.2-0.18-0.32-0.34-0.38L99.82,16.95z'
			/>
			<path
				style={{ fill: '#F4B400' }}
				d='M99.99,36.99c0-5.42,2.96-8.09,7.67-8.09l8.05-0.3c-0.1,0.14-0.63,0.28-0.82,0.35l-5.33,1.85
		c-4.46,1.2-6.41,2.55-7.67,8.09l-2.55,11.02c-0.05,0.2-0.18,0.33-0.34,0.39L99.99,36.99z'
			/>
		</g>
	</svg>
);
export const Money = () => (
	<svg
		version='1.1'
		id='Layer_4'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		viewBox='0 0 128 128'
		// @ts-expect-error TS(2322): Type '{ enableBackground: string; }' is not assign... Remove this comment to see the full error message
		style={{ enableBackground: 'new 0 0 128 128' }}
	>
		<g>
			<g>
				<path
					style={{ fill: '#E0E0E0' }}
					d='M56.48,35.92c0.6-2.69,0.14-1.41-3.73-7.07c-3.48-5.08-2.76-12.3-6.88-15.89
			c-2.53-2.2-5.7-4.67-17.51,1.27c-4.1,2.06-12.29,8.85-21.57,11.96c-7.47,2.5-3.33,5.52-1.74,6.08c5.66,1.99,13.15-2.31,13.15-2.31
			s-10.74,7.02-4.43,8.68c5.12,1.35,11.57-4.8,11.57-4.8s-6.32,6.7-1.15,8.42c3.55,1.18,10.6-5.56,10.6-5.56s-4.93,6.85-2.55,8.3
			c3.62,2.2,9.55-3.47,9.55-3.47s-4.71,6.88-2.25,8c3.68,1.68,9.14-3.04,9.14-3.04S55.4,40.73,56.48,35.92z'
				/>
			</g>
			<path
				style={{ fill: '#BDBDBD' }}
				d='M35.68,13.76c0,0-6.44,3.78-8.79,6.82c-0.31,0.4-2.24,2.33-0.54,3.44c0.43,0.28,1.71,0.1,2.47-0.36
		c3.46-2.1,9.58-7.08,10.25-6.82c0.67,0.26-5.47,4.3-7.45,9.19c-0.74,1.84,0.7,3.16,4.45,0.95c4.08-2.4,7.59-6.63,8.18-6.61
		c0,0-1.64,2.86-3.74,5.12c-2.14,2.31-3.65,4.66-2.07,5.4c1.99,0.94,6.29-1.61,6.29-1.61s-2.74,3.32-6.29,4.07
		c-1.25,0.27-2.23,0.1-2.83-0.58c-0.91-1.04-0.56-2.64-0.56-2.64c-1.89,0.75-5.15,1.97-7.09,0.33c-1.51-1.28,0.55-5.03,0.55-5.03
		s-3.65,2.02-5.73,1.41c-1.08-0.32-2.73-1.88-0.32-5.19C26.26,16.48,35.68,13.76,35.68,13.76z'
			/>
			<path
				style={{ fill: '#BDBDBD' }}
				d='M43.38,50.13c4.17-5.42,8.34-10.83,12.51-16.25l-0.36-1.56c-0.18-0.55-1.82-0.62-4.09,0.69
		c-1.52,0.88-4.31,4.04-6.1,2.3c-1.24-1.2,2.32-5.49,2.32-5.49c-0.49,0.01-4.44,2.65-5.07,5.3c-0.54,2.25-0.02,4.89,5.06,2.32
		c0.15-0.08,0.31-0.17,0.46-0.26c-0.64,1.01-1.22,2.07-1.71,3.15C44.98,43.45,44.14,46.79,43.38,50.13z'
			/>

			<linearGradient
				id='SVGID_1_'
				gradientUnits='userSpaceOnUse'
				x1='47.7173'
				y1='25.5155'
				x2='47.7173'
				y2='25.5155'
				gradientTransform='matrix(0.8038 0.5949 -0.5949 0.8038 58.2507 -37.7388)'
			>
				<stop offset='0' style={{ stopColor: '#43A047' }} />
				<stop offset='0.9992' style={{ stopColor: '#66BB6A' }} />
			</linearGradient>
			<line
				style={{ fill: 'url(#VGID_1_)' }}
				x1='81.39'
				y1='11.13'
				x2='81.46'
				y2='11.18'
			/>
			<path
				style={{ fill: '#66BB6A' }}
				d='M87.13,70.83c-10.05,15.06-18.5,31.65-37.72,36.73c-0.2,0.05-0.41-0.01-0.55-0.16L17.46,72.75
		c-0.93-1.03-0.47-2.66,0.85-3.09c12.91-4.24,20.97-14.34,28.15-25.11c9.9-14.83,18.38-31.15,36.99-36.5
		c0.71-0.27,1.54-0.1,2.08,0.49c0,0,0,0,0,0l0,0c4.17,4.6,35.62,34.54,35.67,34.6C121.22,43.15,94.84,59.27,87.13,70.83z'
			/>
			<g>
				<g>
					<path
						style={{ fill: '#FFFFFF' }}
						d='M58.11,77.71c-0.68-0.68-1.49-1.02-2.43-1.01s-2.16,0.39-3.67,1.14c-2.05,1.09-3.83,1.6-5.32,1.55
				c-1.49-0.06-2.83-0.68-4.01-1.87c-1.21-1.21-1.85-2.56-1.91-4.04c-0.07-1.48,0.44-2.91,1.53-4.29l-1.91-1.92
				c-0.28-0.28-0.28-0.74,0-1.03l0.62-0.62c0.28-0.28,0.74-0.28,1.03,0l1.92,1.93c1.41-1.07,2.9-1.51,4.46-1.33
				c1.4,0.17,2.78,0.86,4.13,2.1c0.26,0.24,0.26,0.66,0.01,0.91l-1.14,1.14c-0.24,0.24-0.63,0.24-0.88,0.01
				c-0.9-0.78-1.83-1.23-2.78-1.33c-1.11-0.12-2.09,0.24-2.93,1.08c-0.88,0.88-1.33,1.78-1.34,2.69c-0.01,0.92,0.39,1.79,1.2,2.6
				c0.75,0.76,1.6,1.11,2.54,1.07c0.94-0.04,2.15-0.44,3.65-1.2s2.77-1.23,3.85-1.42c1.07-0.19,2.05-0.14,2.93,0.14
				c0.88,0.29,1.71,0.82,2.49,1.6c1.24,1.25,1.87,2.63,1.88,4.14c0.01,1.51-0.59,3.01-1.81,4.5l1.6,1.6c0.28,0.28,0.28,0.74,0,1.03
				l-0.61,0.61c-0.28,0.28-0.74,0.28-1.03,0l-1.6-1.6c-1.5,1.24-3.07,1.83-4.7,1.75c-1.45-0.07-2.84-0.69-4.16-1.86
				c-0.27-0.24-0.27-0.66-0.01-0.91l1.16-1.15c0.23-0.23,0.6-0.25,0.85-0.04c0.89,0.77,1.81,1.16,2.76,1.18
				c1.09,0.02,2.13-0.47,3.12-1.46c0.97-0.96,1.5-1.96,1.59-2.97C59.26,79.42,58.91,78.51,58.11,77.71z'
					/>
				</g>
				<polygon
					style={{ fill: '#FFFFFF' }}
					points='60.67,84.74 58.57,85.91 42.34,69.25 41.79,68.69 43.96,67.56 60.19,84.26 		'
				/>
			</g>
			<circle
				style={{ opacity: '0.5', fill: '#2E7D32' }}
				cx='75.88'
				cy='45.55'
				r='14.42'
			/>
			<path
				style={{ fill: '#2E7D32' }}
				d='M92.16,78.42c-5.15,7.72-12.57,22.45-22.79,30.55c-9.38,7.44-21.21,11.11-21.21,11.11
		c0,0-32.55-38.12-32.55-38.12l1.35-10.46l31.92,35.91c0.13,0.14,0.33,0.21,0.52,0.16c19.23-5.08,27.68-21.67,37.73-36.73
		c7.71-11.56,19.24-23.88,34.08-27.68c1.18,1.3,0.66,12.2-0.63,12.63C107.43,60.15,99.36,67.61,92.16,78.42z'
			/>
			<path
				style={{ fill: '#FFC06C' }}
				d='M78.85,83.52l3.02,11.15c0,0,9.2-13.46,10.69-16.72L89.22,67.9C84.76,73.45,78.85,83.52,78.85,83.52
		z'
			/>
			<g>
				<path
					style={{ fill: '#2E7D32' }}
					d='M78.07,17.01c0.77,1.11,2.12,2.26,4.29,2.93c0.77,0.24,1.58,0.36,2.39,0.36
			c1.72,0,3.16-0.53,4.13-1.02c2.43,2.44,5.47,5.41,8.41,8.29c2.88,2.82,5.84,5.72,8.25,8.13c-0.78,1.19-1.4,2.99-1.14,5.42
			c0.12,1.12,0.91,2.26,1.75,3.19c-5.32,3.42-15.53,11.02-26,24.42c-2.21,2.83-4.37,5.97-6.45,9c-5.18,7.54-10.53,15.32-17.65,20.08
			c-0.84-1.37-2.39-3.03-5.16-3.72c-0.67-0.17-1.33-0.25-1.96-0.25c-1.78,0-3.18,0.66-4.18,1.44l-15.1-17.36
			c1.27-0.99,2.46-2.68,2.58-5.29c0.06-1.22-0.59-2.33-1.33-3.17c7.36-5.45,15.13-14.14,19.31-20.38c0.61-0.91,1.24-1.86,1.89-2.84
			C58.64,36.37,67.5,23.05,78.07,17.01 M78.86,14.67c-12.72,6.3-22.85,22.71-30.06,33.45c-4.72,7.03-13.25,16.2-20.6,21.15
			c0,0,2.41,1.71,2.33,3.26c-0.2,4.16-3.58,4.86-3.58,4.86l17.75,20.4c0,0,1.3-2.28,4.22-2.28c0.47,0,0.99,0.06,1.55,0.2
			c4.02,1,4.83,4.54,4.83,4.54c11.34-6.6,18.19-20.27,26.17-30.5c10.83-13.87,21.6-21.73,27.43-25.18c0,0-2.68-2.17-2.83-3.65
			c-0.41-3.89,1.79-5.29,1.79-5.29c-5.15-5.21-13.61-13.32-18.73-18.51c0,0-1.86,1.46-4.39,1.46c-0.6,0-1.24-0.08-1.89-0.28
			C79.17,17.19,78.86,14.67,78.86,14.67L78.86,14.67z'
				/>
			</g>
			<path
				style={{ fill: '#FFECB3' }}
				d='M53.82,33.32l-9.73,14.71l34.76,35.49c0,0,5.68-8.98,10.36-15.62L53.82,33.32z'
			/>
			<path
				style={{ opacity: '0.68', fill: '#D69136' }}
				d='M90.62,72.12c0,0-6.86,3.49-8.73,14.13c-0.71,4.02-0.01,8.42-0.01,8.42l5.66-8.71
		l4.05-12.11L90.62,72.12z'
			/>
			<g>
				<path
					style={{ fill: '#E0E0E0' }}
					d='M88.65,74.43c1.99-1.91,2.65-2.5,8.87-2.95c6.14-0.45,8.96-3.14,16.72-1.53
			c3.28,0.68,8.48,3.68,7.16,15.64c-0.5,4.56-0.38,15.1,1.77,24.65c1.73,7.68-3,5.71-4.3,4.64c-4.64-3.81-4.84-12.44-4.84-12.44
			s-0.44,12.82-5.13,8.29c-3.8-3.68-1.89-12.38-1.89-12.38s-2.46,8.88-6.61,5.34c-2.85-2.43-0.73-11.95-0.73-11.95
			s-3.31,7.77-5.77,6.48c-3.76-1.96-1.98-9.97-1.98-9.97s-3.44,7.59-5.68,6.06c-3.34-2.28-2.13-9.39-2.13-9.39
			S85.09,77.84,88.65,74.43z'
				/>
			</g>
			<path
				style={{ fill: '#BDBDBD' }}
				d='M118.34,79.28c0,0,0.48,6.18,0.05,9.87c-0.27,2.29-0.39,4.39-2.49,4.08
		c-0.51-0.08-1.7-1.07-1.91-4.88c-0.22-4.04,0.62-9.38,0.03-9.78c-0.59-0.41-0.62,6.6-3.54,10.99c-1.1,1.65-2.95,1.83-3.24-2.52
		c-0.32-4.73,2.13-10.33,1.78-10.81c0,0-2.07,2.55-3.27,6.16c-1.2,3.61-1.73,5.31-3.14,4.27c-2.53-1.86,1.18-9.27,1.18-9.27
		s-2.72,2.43-3.9,5.83c-0.75,2.16-0.34,5.71,1.58,6.61c2.26,1.06,3.58-0.83,3.58-0.83s0.48,6.05,3.43,5.92
		c2.83-0.13,3.55-3.32,3.55-3.32s-0.12,3.08,2.74,5.19c1.39,1.03,4.62,1.2,5.02-2.88C120.44,87.29,118.34,79.28,118.34,79.28z'
			/>
			<path
				style={{ fill: '#BDBDBD' }}
				d='M95.07,77.11c-0.61-0.15-1.49,2.6-2.59,4.15c-0.42,0.58-1.32,1.77-2.7,1.67
		c-1.38-0.1-0.29-4.16-0.61-3.94c-0.32,0.22-1.61,2.39-1.1,4.91c0.14,0.7,0.85,1.53,1.55,1.64c2.62,0.42,3.77-2.8,3.77-2.8
		s-0.39,3.85,2.2,4.25c3.13,0.48,3.77-4.45,3.49-4.63c-0.28-0.18-1.29,3.1-3.52,1.7C93.73,82.9,95.68,77.27,95.07,77.11z'
			/>
			<path
				style={{ fill: '#206022' }}
				d='M15.61,81.95l32.43,37.99c0,0,1.45-2.15,1.78-5.5c0.29-2.92-0.73-6.8-0.73-6.8L16.96,71.49
		L15.61,81.95z'
			/>
		</g>
	</svg>
);
export const Glasses = () => <Icon src={glasses} />;

export const Arrow = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='16'
		height='16'
		fill='none'
		viewBox='0 0 16 16'
	>
		<path
			stroke='#888'
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M2 8h12M8 2l6 6-6 6'
		></path>
	</svg>
);
